import { useUserStore } from '~/store/user/user.store';

/* Хранилище контроллера ежедневных бесплатных кейсов */
export const useGenerateUserLevelStore = defineStore('global/generateUserLevel', () => {
  const { $api } = useNuxtApp();

  const userStore = useUserStore();

  const isReadyToGenerate = computed(() => !userStore.currentUser?.wasGeneratedLvl);

  const generateLevel = () => {
    return GlobalUtils.Api.handleRequest(async () => {
      const result = await $api.user.generateLevel();
      if (!result.status) return false;
      if (result.data.success) await userStore.getMe(true);
      return result.data.success;
    });
  };

  return {
    generateLevel,
    isReadyToGenerate,
  };
});
